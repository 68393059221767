import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReactClock from '@uiw/react-clock';
import './Dash.css';
var moment = require('moment');

const sampleData = [
  {
    heading: 'Total open Leads',
    count: 18
  },
  {
    heading: 'Total open Tickets',
    count: 1
  },
  {
    heading: 'Tickets to be Reviewed',
    count: 15
  },
  {
    heading: 'Tickets in Backlog',
    count: 20
  },
  {
    heading: 'Total tickets Closed',
    count: 180
  },
  {
    heading: 'Total Agents Available',
    count: 15
  }
];

function Dash() {
  return (
    <div class="mainWrap">
      <div className='left-side-content' style={{ display: 'flex' }}>
        <div style={{
          minWidth: '25vw', display: 'flex', flexWrap: 'wrap', paddingTop: '30px',
          justifyContent: 'space-around', height: '90vh', flex: '0.6'
        }}>
          {sampleData.map(item => (
            <Card sx={{ width: 150, height: 150, boxShadow: "5px 10px #ff4e5a" }}>
              <CardContent>
                <p style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: "15px", marginBottom: "-20px" }}>{item.heading}</p>
                <p style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: "35px" }}>{item.count}</p>
              </CardContent>
            </Card>
          ))}
        </div>
        <div style={{ display: 'flex', flex: 3, justifyContent: 'space-between' }}>
          <div style={{ width: '45vw', left: '42%', paddingTop: '20px', zIndex: '2' }}>
            <p style={{ fontSize: '20px', fontWeight: 'bolder', color: 'black', marginBottom: '-10px' }}>
              Thought for the day:-</p>
            <p style={{ fontSize: '15px', fontWeight: 'bolder', color: 'black' }}>
              Success is peace of mind which is a direct result of self-satisfaction in knowing you did your best to become the best you are capable of becoming.
            </p>
          </div>
          <div class="clock">
            <ReactClock />
            <p style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: "13px", color: 'red', backgroundColor: 'white' }}>{moment().format('LL')}.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dash;
