import * as React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './Leads.css';

const agentoptions = [
  'Agent 1', 'Agent 2', 'Agent 3', 'Agent 4', 'Agent 5', 'Agent 6', 'Agent 1', 'Agent 2', 'Agent 3', 'Agent 4', 'Agent 5'
];
const defaultOption = agentoptions[0];
const sortptions = [
  'Default', 'Assigned', 'Unassigned'
];
const ndefaultOption = sortptions[0];

function Leads() {
  return(
   <div className='leades-data'>
        <div className='sort_dropdown'>
          <p>Sort By</p>
          <Dropdown options={sortptions} value={ndefaultOption}/>
        </div>
        <div className='responsive-table'>
        <table>
          <thead>
            <tr>
            <th>Lead Id</th>
            <th>Lead Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Assign Agent</th>
            <th>Date Arrived</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>9812357203</td>
              <td>kaushik@gmail.com</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
              <td>04/02/2022</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ankush</td>
              <td>8245367345</td>
              <td>ankush@gmail.com</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
              <td>04/02/2022</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Payal</td>
              <td>9812357203</td>
              <td>Payal@gmail.com</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
              <td>04/02/2022</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Kaushik</td>
              <td>9812357203</td>
              <td>kaushik@gmail.com</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
              <td>04/02/2022</td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className='pagination'>
        <ul>
          <li><a href="#"><i className='fa fa-angle-left'></i></a></li>
          <li className='active'><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li><a href="#"><i className='fa fa-angle-right'></i></a></li>
        </ul>
        </div>
   </div>
  );
}

export default Leads;
