import React, {useState} from "react";
import Modal from 'react-modal';
import './Agents.css';

Modal.setAppElement('#root');

function Agents() {
  const [modalIsopen, setModalIsOpen] = useState(false);
  const [modalIsopenAddAgent, setModalIsOpenAddAgent] = useState(false);
  return(
   <div className='agents-data'>
     <button
     className='add_agent_btn' type="submit"
     style={{display: 'block', marginRight: 0, marginLeft: 'auto' }}
     onClick={() => setModalIsOpenAddAgent(true)}
     >Add Agent
     </button>
        <div className='agents_filters'>
        <h4>Agents Available</h4>
        <div className='agent-search-form'>
          <form>
            <input type="text" name="search" placeholder='Search Agent'/>
            <button type="submit" className='searchButton'><i className='fa fa-search'></i></button>
          </form>
        </div>
        </div>
        <div className='responsive-table'>
        <table>
          <thead>
            <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Joined On</th>
            <th>Total Tickets</th>
            <th>Total Leads</th>
            <th>Change Password</th>
            <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>04/02/2022</td>
              <td>40</td>
              <td>10</td>
              <td>Change Password</td>
              <td><div onClick={() => setModalIsOpen(true)} className="delete_btn"><i className='fa fa-trash'></i></div></td>
            </tr>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>04/02/2022</td>
              <td>40</td>
              <td>10</td>
              <td>Change Password</td>
              <td><div onClick={() => setModalIsOpen(true)} className="delete_btn"><i className='fa fa-trash'></i></div></td>
            </tr>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>04/02/2022</td>
              <td>40</td>
              <td>10</td>
              <td>Change Password</td>
              <td><div onClick={() => setModalIsOpen(true)} className="delete_btn"><i className='fa fa-trash'></i></div></td>
            </tr>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>04/02/2022</td>
              <td>40</td>
              <td>10</td>
              <td>Change Password</td>
              <td><div onClick={() => setModalIsOpen(true)} className="delete_btn"><i className='fa fa-trash'></i></div></td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className='pagination'>
        <ul>
          <li><a href="#"><i className='fa fa-angle-left'></i></a></li>
          <li className='active'><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li><a href="#"><i className='fa fa-angle-right'></i></a></li>
        </ul>
        </div>
           
           <Modal isOpen={modalIsopen} shouldCloseOnOverlayClick={false} onRequestClose={() => setModalIsOpen(false)}>
            <div className="modal-content">
            <button className="closemodal" onClick={() => setModalIsOpen(false)}><i className="fa fa-times"></i></button>
            <h2>Are you sure you want to delete</h2>
            <div className="btns">
            <button className="submit_value">Yes</button>
            <button className="cancel_value" onClick={() => setModalIsOpen(false)}>Cancel</button>
            </div>
            </div>
           </Modal>

           <Modal isOpen={modalIsopenAddAgent} shouldCloseOnOverlayClick={true} onRequestClose={() => setModalIsOpenAddAgent(false)}
           className='addAgentModal'
           >
           <div className="add_agent">
          <h2>Add Agent</h2>
          <form>
          <div className="form-group">
            <input type="text" className="form-control"  placeholder="Name" />
          </div>
          <div className="form-group">
            <input type="email" className="form-control"  placeholder="Enter email" />
          </div>
          <div className="form-group">
            <input type="password" className="form-control"  placeholder="Enter Password" />
          </div>
          <div className="form-group">
            <input type="date" className="form-control" />
          </div>
          <div className="form-group">
            <button type="submit" className="submit_button">Add</button>
          </div>
          </form>
        </div>
           </Modal>
   </div>
  );
}

export default Agents;
