import React from 'react';

function Settings() {
  return(
      <div>
          Settings
      </div>
  );
}

export default Settings;
