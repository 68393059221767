import './App.css';
import Login from './components/Login';
import Leads from './components/Leads';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Main from './components/Main';
import Dash from './components/Dash';
import Tickets from './components/Tickets';
import Agents from './components/Agents';
import Settings from './components/Settings';

function App() {
  return (
    <Router>
    <Switch>
        <Route exact  path="/Login" component={Login} />
        <Route exact path="/" render={() => <Main><Dash/></Main>} />
        <Route exact path="/Leads" render={() => <Main><Leads/></Main>} />
        <Route exact path="/Tickets" render={() => <Main><Tickets/></Main>} />
        <Route exact path="/Agents" render={() => <Main><Agents/></Main>} />
        <Route exact path="/Settings" render={() => <Main><Settings/></Main>} />
    </Switch>
</Router>
  );
}

export default App;