import * as React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './Tickets.css';

const agentoptions = [
  'Agent 1', 'Agent 2', 'Agent 3', 'Agent 4', 'Agent 5', 'Agent 6', 'Agent 1', 'Agent 2', 'Agent 3', 'Agent 4', 'Agent 5'
];
const defaultOption = agentoptions[0];
const sortptions = [
  'Default', 'Assigned', 'Unassigned'
];
const ndefaultOption = sortptions[0];

function Tickets() {
  return(
   <div className='tickets-data'>
        <div className='tickets_filters'>
        <div className='search-form'>
          <form>
            <input type="text" name="search" placeholder='Search'/>
            <button type="submit" className='searchButton'><i className='fa fa-search'></i></button>
          </form>
        </div>
        <div className='sort_dropdown'>
          <p>Sort By</p>
          <Dropdown options={sortptions} value={ndefaultOption}/>
        </div>
        </div>
        <div className='responsive-table'>
        <table>
          <thead>
            <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Category</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Payment</th>
            <th>Date</th>
            <th>Assign Agent</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>Category</td>
              <td>9812357203</td>
              <td>kaushik@gmail.com</td>
              <td>Payment</td>
              <td>04/02/2022</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
            </tr>
            <tr>
            <td>1</td>
              <td>Kaushik</td>
              <td>Category</td>
              <td>9812357203</td>
              <td>kaushik@gmail.com</td>
              <td>Payment</td>
              <td>04/02/2022</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
            </tr>
            <tr>
            <td>1</td>
              <td>Kaushik</td>
              <td>Category</td>
              <td>9812357203</td>
              <td>kaushik@gmail.com</td>
              <td>Payment</td>
              <td>04/02/2022</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
            </tr>
            <tr>
              <td>1</td>
              <td>Kaushik</td>
              <td>Category</td>
              <td>9812357203</td>
              <td>kaushik@gmail.com</td>
              <td>Payment</td>
              <td>04/02/2022</td>
              <td><Dropdown options={agentoptions} value={defaultOption} /></td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className='pagination'>
        <ul>
          <li><a href="#"><i className='fa fa-angle-left'></i></a></li>
          <li className='active'><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">3</a></li>
          <li><a href="#"><i className='fa fa-angle-right'></i></a></li>
        </ul>
        </div>
   </div>
  );
}

export default Tickets;
